import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel, PanelBody } from './../../components/panel/panel.js';
import { Table, Skeleton, Form, Row, Col, Select, Space } from 'antd';
import style from './DocumentListing.module.scss';
import DocumentUploadModal from "./DocumentUploadModal.js"

import { useCurrentCompany } from '../../features/company/companySlice.js';
import { getDocumentDataColumns } from './DocumentHelper.js'
import {SearchOutlined} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useGetDocument, executeDeleteDocument } from '../../features/document/documentApi.js';
import { useDeleteDocumentMutation,	fetchDocument } from '../../features/document/documentApi.js';
import { ENTITY_TYPE_VEHICLE_MODEL } from "./constant.js"
import { useUserKey } from '../../features/user/ssoSlice';
import { isValidHttpUrl } from '../../utils/strings.js';

const InstallationDocumentListing = () => {
	const dispatch = useDispatch();
	const currentCompany = useCurrentCompany();
	const { i18n, t } = useTranslation();
	const [filterForm] = Form.useForm();
	const [selectedDocument, setSelectedDocument] = useState();
	const [deleteDocument, response] = useDeleteDocumentMutation();
	const [isDocumentUploadModalVisible, setIsDocumentUploadModalVisible] = useState(false);
	const { data: documents, isFetching } = useGetDocument(currentCompany.id, ENTITY_TYPE_VEHICLE_MODEL);
	const [filteredDocument, setFilteredDocument] = useState(documents);
	const [searchObj, setSearchObj] = useState(null);
	const userKey = useUserKey();

	useEffect(() => {
		let filteredData =  documents;
		if (searchObj) {
			if (searchObj.any && searchObj.any.length !== 0){
				const keywords = searchObj.any.map((str) => str.toLowerCase());

				filteredData = (filteredData || []).filter(i=> 
					i.data.some(vm => keywords.find(k=>vm.make.toLowerCase().includes(k) ||
					 vm.model.toLowerCase().includes(k) ||
					 vm.year?.toString().toLowerCase().includes(k) ||
					 vm.source?.toString().toLowerCase().includes(k))) || 
					keywords.includes(i.name?.toLowerCase()));
			}
		}
		setFilteredDocument(filteredData);
	}, [searchObj, documents]);

	const onFinish = (e) => {
		setSearchObj(e);
	};

	const onDelete = (e) => {
		const parsedPayload = {
			attachmentId: e.attachmentId,
			ids: e.data.map(i => i.entityId),
			entityType : ENTITY_TYPE_VEHICLE_MODEL
		}

		executeDeleteDocument(parsedPayload, deleteDocument, dispatch);
	}

	const onViewDoc = async (e) => {
		var win = window.open();
		if (e.mimeType === "text/*") {
			win.location = e.url;
		}
		else {
			fetchDocument(e.id, dispatch, userKey).then(e => {
				if (isValidHttpUrl(e)) {
					win.location = e;
				}
				else {
					win.document.write(e);
				}
			}).catch(e => win.close());
		}
	}

	const onEdit = (e) => {
		setSelectedDocument(e)
		setIsDocumentUploadModalVisible(true)
	}

	return (
		<div>
			<Panel noPadding style={{ marginBottom: "15px" }}>
				<PanelBody>
					<Form form={filterForm} layout="inline" onFinish={onFinish} style={{ background: "#f2f2f2" }}>
						<Row className={`${style.fullWidthRow} ${style.searchPanelRow}`}>
							<Col xs={{ span: 24 }}>
								<Form.Item name="any">
									<Select onChange={() => { filterForm.submit() }}
										maxTagCount="responsive"
										suffixIcon={<SearchOutlined />}
										mode="tags"
										className={style.fullWidthRow} />
								</Form.Item>
							</Col>
						</Row>
					</Form>

					<Row className={style.fullWidthRow}>
						<Col xs={{ span: 24 }}>
							{isFetching || documents === undefined ? <Skeleton active /> :
								<Table
									pagination={{
										showSizeChanger: true
									}}
									size="small"
									tableLayout='fixed'
									rowKey={(record) => record.rowId}
									columns={getDocumentDataColumns(onViewDoc, onEdit, onDelete)}
									dataSource={filteredDocument}
								/>
							}
						</Col>
					</Row>
				</PanelBody>
			</Panel>
			<DocumentUploadModal
				document={selectedDocument}
				entityType={ENTITY_TYPE_VEHICLE_MODEL}
				isModalOpen={isDocumentUploadModalVisible}
				onClose={() => {
					setIsDocumentUploadModalVisible(false);
					setSelectedDocument(undefined);
				}} />
		</div>
	)
}

export default InstallationDocumentListing;