import React from 'react';
import { Card, Button } from 'antd';
import { Buttons } from '../control/Buttons'

const Panel = ({
	header, 
	style, 
	noPadding, 
	bodyStyle,
	className, 
	newButtonAction, 
	buttons, 
	...props
}) => {
	const paddingProps = noPadding ? { padding: 0 } : {};
	return (
		<Card title={header} style={style}
			headStyle={{ paddingLeft: 24, paddingRight: 0 }}
			bodyStyle={{ ...paddingProps, ...bodyStyle }}
			bordered={false}
			className={className}
			extra={(newButtonAction || buttons) ?
				<div style={{ display: "flex", alignItems: "center", paddingRight: "15px" }}>{
					newButtonAction ? newButtonAction() : null
				}
					{
						buttons ? <Buttons
							moreButtons={buttons}
							{...props}
						/> : null
					}
				</div> : null
			}>
			{props.children}
		</Card>
	);
};

const PanelBody = props => {
	return (
		<div className={props.className}>
			{props.children}
		</div>
	);
};

export { Panel, PanelBody };
