const ENV = Object.freeze({
  TEST_ANZ: 'https://tst.api-au.telematics.com/v1',
  QA_ANZ: 'https://qa.api-au.telematics.com/v1',
  STAGING_ANZ: 'https://stg.api-au.telematics.com/v1',
  PROD_ANZ: 'https://api-au.telematics.com/v1',
  PROD_UK: 'https://api-uk.telematics.com/v1',
  PROD_US: 'https://api-us.telematics.com/v1',
  PROD_MX: 'https://api-mx.telematics.com/v1'
});

const NON_PROD = [ENV.QA_ANZ, ENV.STAGING_ANZ, ENV.TEST_ANZ];

export const FeatureFlag = Object.freeze({
  npi: {
    flag: 'npi',
    allow: NON_PROD
  },
});

export const Permission = {
  COMMON_EDIT: 'COMMON_EDIT',
  COMMON_READ: 'COMMON_READ',
  IMPORT: 'IMPORT',
  EXPORT: 'EXPORT',
  MERGE_VEHICLE_MODEL: 'MERGE_VEHICLE_MODEL',
  AUDIT: 'AUDIT'
}

export const Role = {
  ADMIN: 'VLT Admin',
  READONLY_ADMIN: 'VLT Read-Only Admin',
  USER: 'VLT User',
  SITE_ADMIN: 'Site Admin'
}

export const RoleToPermission = {
  [Role.ADMIN]: [
    Permission.COMMON_EDIT,
    Permission.COMMON_READ,
    Permission.MERGE_VEHICLE_MODEL,
    Permission.EXPORT,
    Permission.AUDIT
  ],
  [Role.READONLY_ADMIN]: [
    Permission.COMMON_READ,
    Permission.EXPORT,
    Permission.AUDIT
  ],
  [Role.USER]: [
    Permission.COMMON_READ,
    Permission.EXPORT,
  ],
  [Role.SITE_ADMIN]: [
    Permission.COMMON_EDIT,
    Permission.COMMON_READ,
    Permission.IMPORT,
    Permission.EXPORT,
    Permission.MERGE_VEHICLE_MODEL,
    Permission.AUDIT
  ]
}

export const getRolePermission = (userData) => {
  if (userData?.siteAdmin && userData?.companyId === 1) {
    return RoleToPermission[Role.SITE_ADMIN];
  }
  else if ((userData?.roles || []).filter(i=>i.name.includes(Role.ADMIN)).length !== 0) {
    return RoleToPermission[Role.ADMIN];
  }
  else if ((userData?.roles || []).filter(i=>i.name.includes(Role.READONLY_ADMIN)).length !== 0) {
    return RoleToPermission[Role.READONLY_ADMIN];
  }
  else if ((userData?.roles || []).filter(i=>i.name.includes(Role.USER)).length !== 0) {
    return RoleToPermission[Role.USER];
  }

  return [];
}

export const checkIsAdminOrSiteAdmin = (userData) => {
  return (userData?.siteAdmin && userData?.companyId === 1) || 
      (userData?.roles || []).filter(i=>i.name.includes(Role.ADMIN) || i.name.includes(Role.READONLY_ADMIN)).length !== 0;
}