import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Panel, PanelBody } from './../../components/panel/panel.js';
import { Table, Skeleton, Form, Row, Col, Select, Space } from 'antd';
import style from './DocumentListing.module.scss';

import { useCurrentCompany } from '../../features/company/companySlice.js';
import { getDocumentDataColumnsForHardware } from './DocumentHelper.js'
import { useDispatch } from 'react-redux';
import { useGetDocument, executeDeleteDocument} from '../../features/document/documentApi.js';
import {
	useDeleteDocumentMutation,
	fetchDocument
} from '../../features/document/documentApi.js';
import { AUDIT_COMMENTS, ENTITY_TYPE_DEVICE_MODEL } from "./constant.js"
import { useUserKey } from '../../features/user/ssoSlice';
import { isValidHttpUrl } from '../../utils/strings.js';
import DocumentUploadModal from './DocumentUploadModal.js';

const HardwareDocumentListing = () => {
	const dispatch = useDispatch();
	const currentCompany = useCurrentCompany();
	const { t } = useTranslation();
	const [selectedDocument, setSelectedDocument] = useState();
	const [deleteDocument] = useDeleteDocumentMutation();
	const [isDocumentUploadModalVisible, setIsDocumentUploadModalVisible] = useState(false);
	const { data: documents, isFetching } = useGetDocument(currentCompany.id, ENTITY_TYPE_DEVICE_MODEL);
	const userKey = useUserKey();

	const onDelete = (e) => {
		const parsedPayload = {
			attachmentId: e.attachmentId,
			entityId: e.id,
			comment: AUDIT_COMMENTS.UNLINK_VEHICLE_MODEL_DOCUMENT,
			entityType : ENTITY_TYPE_DEVICE_MODEL
		}

		executeDeleteDocument(parsedPayload, deleteDocument, dispatch);
	}

	const onViewDoc = async (e) => {
		var win = window.open();
		if (e.mimeType === "text/*") {
			win.location = e.url;
		}
		else {
			fetchDocument(e.id, dispatch, userKey).then(e => {
				if (isValidHttpUrl(e)){
					win.location = e;
				}
				else{
					win.document.write(e);
				}
			}).catch(e => win.close());
		}
	}

	const onEdit = (e) => {
		setSelectedDocument(e)
		setIsDocumentUploadModalVisible(true)
	}

	return (
		<div>
			<Panel noPadding style={{ marginBottom: "15px" }}>
				<PanelBody>
					<Row className={style.fullWidthRow}>
						<Col xs={{ span: 24 }}>
							{isFetching || documents === undefined ? <Skeleton active /> :
								<Table
									pagination={{
										showSizeChanger: true
									}}
									size="small"
									tableLayout='fixed'
									rowKey={(record) => record.rowId}
									columns={getDocumentDataColumnsForHardware(onViewDoc, onEdit, onDelete)}
									dataSource={documents}
								/>
							}
						</Col>
					</Row>
				</PanelBody>
			</Panel>
			<DocumentUploadModal
				document={selectedDocument}
				entityType={ENTITY_TYPE_DEVICE_MODEL}
				isModalOpen={isDocumentUploadModalVisible}
				onClose={() => {
					setIsDocumentUploadModalVisible(false);
					setSelectedDocument(undefined);
				}} />
		</div>
	)
}

export default HardwareDocumentListing;