import React from 'react';
import { Button, Row, Col, Typography } from 'antd';
import style from './VehicleCompatibility.module.scss';
import { CloseOutlined, LinkOutlined, FilePdfOutlined } from '@ant-design/icons';
import { getFileSize } from '../../utils/files.js';

const { Text } = Typography;

const VehicleDocumentList = ({documents, onRemoved}) => {
	return (
		<Row className={style.fullWidthRow}>
			{documents.map((file, index) => {
				return (
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} key={index}>
						<div className={style.documentListPanel}>
							<Row className={style.fullWidthRow}>
								<Col xs={{ span: 4 }} style={{display:"flex"}}>
									{file.mimeType === "text/*" ? <LinkOutlined style={{ marginRight: 15, fontSize: 26, padding: 5 }} /> : <FilePdfOutlined style={{ marginRight: 15, fontSize: 26, padding: 5, color:"darkred" }} />}
								</Col>
								<Col xs={{ span: 16 }} style={{ alignContent: "center", paddingLeft:5 }}>
									<span className={style.centerPanel} style={{ flexDirection: "column" }}>
										<Text ellipsis={{ rows: 1, expandable: false }} className={style.fullWidthRow} style={{ textAlign: "left" }}>{file.name}</Text>
										<Text type="secondary" className={style.fullWidthRow} style={{ textAlign: "left" }}>{file.file?.size ? getFileSize(file.file) : "-"}</Text>
									</span>
								</Col>
								<Col xs={{ span: 4 }} style={{ alignContent: "center", textAlign: "right" }}>
									<Button type="text" size="small" icon={<CloseOutlined />} onClick={() => {
										onRemoved(index)
									}} />
								</Col>
							</Row>
						</div>
					</Col>
				)
			})}
		</Row>
	)
}

export default VehicleDocumentList;