import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Spin, Space, Form, Button, Row, Col, Select, Input, Typography } from 'antd';
import { addToast } from "../../features/toast/toastsSlice"

import {
	SearchOutlined,
	UploadOutlined,
	ContainerOutlined,
	PlusCircleOutlined
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../features/header/headerSlice.js';
import styles from './VehicleCompatibilitySearch.module.scss';
import logo from "../../assets/css/default/image/logo_color.svg";
import VehicleCompatibilitySearchModal from './VehicleCompatibilitySearchModal.js';
import { useGrouppedVehicleCompatibility } from '../../features/vehicleModels/vehicleModelApi.js';
import { useCurrentCompany } from '../../features/company/companySlice.js';
import { VehicleMakeModelYearFormModal } from './VehicleMakeModelYearFormModal.js';
import { useGetRegionsQuery } from '../../features/regions/regionsApi.js';
import { useUserInfo } from '../../features/user/userApi.js';
import Multiselect from '../../features/multiselect/Multiselect.js';
import { getMMYCombination } from './VehicleModelHelper.js';
import { useMediaQuery } from 'react-responsive';
import { RouteKey } from '../../config/page-key.js';
import { Can } from '../../features/user/Can.js';
import { Permission } from '../../features/user/Permission.js';
const { Text, Title } = Typography;

const VehicleCompatibilitySearch = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { i18n, t } = useTranslation();
	const [searchForm] = Form.useForm();
	const currentCompany = useCurrentCompany();
	const { vehicleCompatibility, isFetching: isVehicleCompatibilityLoading } = useGrouppedVehicleCompatibility(currentCompany.id);
	const [showCsvSearch, setShowCsvSearch] = useState(false);
	const [newVehicleModelModal, setNewVehicleModelModal] = useState(false);
	const data = useUserInfo();
	const { data: regions } = useGetRegionsQuery(undefined, { skip: data.id === undefined });
	const company = useCurrentCompany();
	const formValues = Form.useWatch([], searchForm);
	const isMobile =useMediaQuery({ maxWidth: 767 })

	const fullValues = useMemo(() => {
		return getMMYCombination(searchForm, vehicleCompatibility);
	}, [ vehicleCompatibility, formValues ]);

	const feedbackUrl = useMemo(() => {
		let link;

		if (regions == null || company == null) {
			return link;
		}
		const region = regions.find(r => r.code === company.country);

		if (region == null) {
			return link;
		}

		try {
			const configJson = JSON.parse(region.config);
			if (configJson.vlt_feedback_url) {
				link = configJson.vlt_feedback_url;
			}
		}
		catch {
			console.error('Region has no config or config json has format problem.');
		}
		return link;
	}, [company, regions]);

	useEffect(() => {
		dispatch(setPageTitle(t("VehicleModel.VehicleLookupTool")));
	}, [dispatch]);

	const onFinish = (e) => {
		history.push(RouteKey.VEHICLE_COMPATIBILITY, (!!e.make || !!e.model || !!e.year) ? e : undefined)
	};

	return (
		<Spin spinning={isVehicleCompatibilityLoading}>
			<Space direction={"vertical"} className={styles.mainPanel} size={40}>
				<div className={styles.LogoWordmark}>
					<img src={logo} />
				</div>
				<Title level={4} className={styles.centerRow}>{t("VehicleModel.VehicleSearchPlaceHolder")}</Title>
				<Form form={searchForm} layout="inline" onFinish={onFinish} className={styles.centerRow}>
					<Row justify="center" align="middle" style={{width:"80%"}}>
						<Col xs={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 4 }} className={"mb-2"}>
							<Form.Item name="make" >
								<Multiselect
									options={fullValues.make}
									placeholder={t("VehicleModel.Make")}
									showBadge={false}
									size="large"
									isLoading={isVehicleCompatibilityLoading} />
							</Form.Item>
						</Col>
						<Col xs={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 4 }} className={"mb-2"}>
							<Form.Item name="model">
								<Multiselect
									options={fullValues.model}
									showBadge={false}
									size="large"
									placeholder={t("VehicleModel.Model")}
									isLoading={isVehicleCompatibilityLoading} />
							</Form.Item>
						</Col>
						<Col xs={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 4 }} className={"mb-2"}>
							<Form.Item name="year">
								<Multiselect
									options={fullValues.year}
									showBadge={false}
									size="large"
									placeholder={t("VehicleModel.Year")}
									isLoading={isVehicleCompatibilityLoading} />
							</Form.Item>
						</Col>
						<Col xs={{ span: 24 }} lg={{ span: 6 }} className={["mb-2", isMobile ? styles.centerRow : undefined]}>
							<Space>
								<Button
									type="primary"
									size="large"
									htmlType="submit"
								>
									{t('Common.Search')} <SearchOutlined />
								</Button>
								<Button size="large" onClick={() => { setShowCsvSearch(true) }}>
									{t('VehicleModel.CsvSearch')} <UploadOutlined />
								</Button>
							</Space>
						</Col>
					</Row>
				</Form>
				<Row justify="center" align="middle">
					<Col xs={{ span: 24 }} className={styles.centerRow}>
						<Space>
							{feedbackUrl && <Button
								type="text"
								size="large"
								onClick={() => {
									window.open(feedbackUrl, "_blank")
								}}
							>
								<ContainerOutlined /> {t('VehicleModel.ReportIssue')}
							</Button>
							}
							
							<Can requiredPermission={[Permission.COMMON_EDIT]}>
								<Button size="large" type="text" onClick={() => { setNewVehicleModelModal(true) }}>
									<PlusCircleOutlined /> {t('VehicleModel.AddVehicleModel')}
								</Button>
							</Can>
						</Space>
					</Col>
				</Row>
			</Space>
			<VehicleCompatibilitySearchModal
				isModalOpen={showCsvSearch}
				vehicleCompatibilityList={vehicleCompatibility}
				onComplete={(keywords, searchedData, totalRecord, matchedRecord) => {
					setShowCsvSearch(false);
					history.push(RouteKey.VEHICLE_COMPATIBILITY, {records:searchedData.map(i=>i.id)});

					dispatch(
						addToast({
							title: i18n.t("VehicleModel.CsvSearchSubmitted"),
							type: "success"
						})
					);
				}}
				groupedModel={true}
				onClose={() => { setShowCsvSearch(false) }} />
			<VehicleMakeModelYearFormModal
				isModalOpen={newVehicleModelModal}
				onClose={() => {
					setNewVehicleModelModal(false);
				}} />
		</Spin>
	)
}

export default VehicleCompatibilitySearch;