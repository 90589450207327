import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, LinkOutlined, PlusCircleOutlined, CloseCircleOutlined, UploadOutlined, FilePdfOutlined, CarOutlined } from '@ant-design/icons';
import { Button, Row, Col, Form, Select, Space, Tabs, Typography, Upload, Alert, Radio , Input, Segmented, Tooltip } from 'antd';
import { useCurrentCompany } from '../../features/company/companySlice.js';
import { ResponsiveModal } from '../../features/responsive';
import { useDispatch } from 'react-redux';
import style from './DocumentListing.module.scss';
import { getFileSize } from '../../utils/files.js';
import {
  useGetDeviceModelsQuery,
  useGetVehicleModelsQuery
} from '../../features/vehicleModels/vehicleModelApi';
import {
  executeSaveDocument,
  useSaveDocumentMutation,
  useDeleteDocumentMutation
} from '../../features/document/documentApi.js';
import { ENTITY_TYPE_DEVICE_MODEL, ENTITY_TYPE_VEHICLE_MODEL } from "./constant.js"

const { Text } = Typography;

const DocumentUploadModal = ({ document, isModalOpen, onClose, entityType }) => {
  const { Dragger } = Upload;
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const currentCompany = useCurrentCompany();
  const [form] = Form.useForm();
  const [documentForm] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [selectedYearValue, setSelectedYearValue] = useState([]);
  const [selectedSourceValue, setSelectedSourceValue] = useState([]);
  const [selectedModelValue, setSelectedModelValue] = useState([]);
  const [selectedMakeValue, setSelectedMakeValue] = useState([]);
  const [selectedVehicleModels, setSelectedVehicleModels] = useState([]);
  const [saveDocument] = useSaveDocumentMutation();
  const [deleteDocument] = useDeleteDocumentMutation();
  const [selectedDocumentType, setSelectedDocumentType] = useState("LoadFile");
  const [selectedEntityType, setSelectedEntityType] = useState(entityType);
  const [isDirty, setIsDirty] = useState(false);
  const [isZipFile, setIsZipFile] = useState(false);
  const { data: allDeviceModels, isFetching: isFetchingDeviceModel } = useGetDeviceModelsQuery({ companyId: currentCompany.id, compatibleModelOnly : true }, { pollingInterval: 3600000, skip: currentCompany.id === undefined });
  const { data: vehicleModels, isFetching } = useGetVehicleModelsQuery({ companyId: currentCompany.id }, { pollingInterval: 1800000, skip: currentCompany?.id === undefined });

  const renderVehicleModelPanel = () => {
    let groupedData = selectedVehicleModels.reduce((result, item) => {
      const { make } = item;

      const key = `${make}`;
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
      return result;
    }, [selectedVehicleModels]);

    return (
      <Space direction={"vertical"} className={style.MMYPanel} size={10}>
        <Form.Item name="make" style={{ margin: 0 }}>
          <Select
            showSearch
            allowClear
            disabled={isZipFile}
            mode={"multiple"}
            onChange={handleMakeValueSelect}
            maxTagCount={"responsive"}
            style={{ width: "100%" }}
            loading={isFetching}
            placeholder={t("VehicleModel.Make")}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={makeValues}
          />
        </Form.Item>
        <Form.Item name="model" style={{ margin: 0 }}>
          <Select
            showSearch
            style={{ width: "100%" }}
            allowClear
            disabled={isZipFile}
            mode={"multiple"}
            onChange={handleModelValueSelect}
            loading={isFetching}
            maxTagCount={"responsive"}
            placeholder={t("VehicleModel.Model")}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={modelValues}
          />
        </Form.Item>
        <Form.Item name="year" style={{ margin: 0 }}>
          <Select
            showSearch
            allowClear
            disabled={isZipFile}
            mode={"multiple"}
            onChange={handleYearValueSelect}
            style={{ width: "100%" }}
            maxTagCount={"responsive"}
            loading={isFetching}
            placeholder={t("VehicleModel.Year")}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={yearValues}
          />
        </Form.Item>
        <Form.Item name="source" style={{ margin: 0 }}>
          <Select
            showSearch
            allowClear
            disabled={isZipFile}
            mode={"multiple"}
            onChange={handleSourceValueSelect}
            style={{ width: "100%" }}
            maxTagCount={"responsive"}
            loading={isFetching}
            placeholder={t("VehicleModel.Source")}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={sourceValues}
          />
        </Form.Item>

        <Form.List
          name="vehicleModelIds"
          rules={[
            {
              validator: async (_, names) => {
                if ((!selectedVehicleModels || selectedVehicleModels.length === 0) && !isZipFile) {
                  return Promise.reject(new Error(t('ValidationErrors.VehicleModelRequired')));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                <Form.Item className={"mb-2"}>
                  <Button
                    type="dashed"
                    disabled={(!selectedMakeValue ||
                      !selectedModelValue ||
                      !selectedYearValue ||
                      !selectedSourceValue ||
                      selectedMakeValue.length == 0 ||
                      selectedModelValue.length == 0 ||
                      selectedYearValue.length == 0 ||
                      selectedSourceValue.length == 0)}
                    onClick={() => {
                      addVehicleModel(add);
                    }}
                    style={{ width: '100%' }}
                    icon={<PlusCircleOutlined />}
                  >
                    {t("Document.DocumentSetupModal.AddVehicle")}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
                
                <div style={{ maxHeight: "250px", overflow: "auto" }}>
                  {Object.keys(groupedData).slice(1).map((key) => {
                    return (
                      <Form.Item className="mb-2"
                        key={key}
                      >
                        <Row className={style.vehicleModelListingRow}>
                          <Col xs={{ span: 20 }}>
                            <Space>
                              <CarOutlined />
                              <Text className={style.selectedMMYText}>{Array.from(new Set(groupedData[key].map(v => v.make))).join(' ')}</Text>/
                              <Text className={style.selectedMMYText}>{Array.from(new Set(groupedData[key].map(v => v.model))).join(' ')}</Text>/
                              <Text className={style.selectedMMYText}>{Array.from(new Set(groupedData[key].map(v => v.yearOfManufacture))).join(' ')}</Text>/
                              <Text className={style.selectedMMYText}>{Array.from(new Set(groupedData[key].map(v => v.source))).join(' ')}</Text>
                            </Space>
                          </Col>
                          <Col xs={{ span: 4 }} className={style.dynamicVehicleModelField}>
                            {!isZipFile && <CloseCircleOutlined
                              onClick={() => {
                                let newSelectedVehicleModels = [...selectedVehicleModels];
                                newSelectedVehicleModels = newSelectedVehicleModels.filter(i => groupedData[key].find(k => k.id === i.id) === undefined);
                                setSelectedVehicleModels(newSelectedVehicleModels);
                                setIsDirty(true);

                                form.setFieldValue('vehicleModelIds', newSelectedVehicleModels.map(i => i.id));
                              }}
                            />}
                          </Col>
                        </Row>
                      </Form.Item>
                    )
                  })}
                </div>
              </>
            )
          }}
        </Form.List>
      </Space>
    );
  }

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedYearValue([]);
      setSelectedMakeValue([]);
      setSelectedModelValue([]);
      setSelectedSourceValue([]);
      setFileList([]);
      setSelectedVehicleModels([]);
      setSelectedFile();
      form.resetFields();
      setIsDirty(false);
      setIsZipFile(false);
    }
    else if (document) {
      if (entityType === ENTITY_TYPE_VEHICLE_MODEL) {
        setSelectedVehicleModels(document.data.map(i => { return { ...vehicleModels.find(k => k.id === i.entityId), ...{ entityType: i.entityType } } }));
      }
      else {
        form.setFieldValue("deviceModelId", parseInt(document.data[0].entityId));
      }

      let documentData = { name: document.name };
      if (document.mimeType === "text/*") {
        documentData.url = document.url;
      }
      else {
        documentData.file = document.name;
      }

      setFileList([documentData])
      form.setFieldValue("documents", 1);
      setIsZipFile(document.mimeType === "application/zip");
      setIsDirty(false);
    }
  }, [isModalOpen, document]);

  const makeValues = useMemo(() => {
    const makeList = (vehicleModels || []).map(i => i.make);
    return Array.from(new Set(makeList)).map(data => { return { label: data, value: data } });
  }, [vehicleModels]);

  const modelValues = useMemo(() => {
    const modelListWithDuplicates = (vehicleModels || []).reduce((accumulator, makeModelYear) => {
      if ((selectedMakeValue || []).includes(makeModelYear.make)) {
        accumulator.push(makeModelYear.model);
      }
      return accumulator;
    }, []);

    const modelList = modelListWithDuplicates.reduce((accumulator, model, index) => {
      if (modelListWithDuplicates.indexOf(model) === index) {
        accumulator.push({ label: model, value: model });
      }

      return accumulator.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    }, []);
    return modelList;
  }, [vehicleModels, selectedMakeValue]);

  const yearValues = useMemo(() => {
    const yearListWithDuplicates = (vehicleModels || []).reduce((accumulator, makeModelYear) => {
      if ((selectedMakeValue || []).includes(makeModelYear.make) && (selectedModelValue || []).includes(makeModelYear.model) && makeModelYear.yearOfManufacture) {
        accumulator.push(makeModelYear.yearOfManufacture);
      }
      return accumulator;
    }, []);

    const yearList = yearListWithDuplicates.reduce((accumulator, yearOfManufacture, index) => {
      if (yearListWithDuplicates.indexOf(yearOfManufacture) === index) {
        accumulator.push({ label: yearOfManufacture?.toString(), value: yearOfManufacture });
      }

      return accumulator.sort((a, b) => (a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1));
    }, []);
    return yearList;
  }, [vehicleModels, selectedMakeValue, selectedModelValue]);

  const sourceValues = useMemo(() => {
    return (vehicleModels || []).reduce((accumulator, makeModelYear) => {
      if (
        selectedMakeValue.includes(makeModelYear.make) &&
        selectedModelValue.includes(makeModelYear.model) &&
        selectedYearValue.includes(parseInt(makeModelYear.yearOfManufacture, 0))
      ) {
        const source = makeModelYear.displaySource
          ? makeModelYear.displaySource
          : makeModelYear.source;
        if (accumulator.find(i => i.value === source) === undefined) {
          accumulator.push({
            label: source || 'Unknown',
            value: source
          });
        }
      }
      return accumulator;
    }, []);
  }, [selectedMakeValue, selectedModelValue, selectedYearValue, vehicleModels]);

  const onFinish = (e) => {
    const parsedPayload = {
      ids: selectedEntityType === ENTITY_TYPE_DEVICE_MODEL ? [e.deviceModelId] : selectedVehicleModels.map(data => data.id),
      entityType: selectedEntityType
    }

    if (document && fileList.filter(i=> !!i.file && !(i.file instanceof File)).length !== 0) {
      parsedPayload["attachmentId"] = document.attachmentId;
    }

    parsedPayload["file"] = fileList.filter(i=> i.file instanceof File);
    parsedPayload["link"] = fileList.filter(i=> !!i.url);

    let deletePayload;

    if (document) {
      deletePayload = {
        attachmentId: document.attachmentId,
        ids: document.data.map(i => i.entityId),
        entityType: selectedEntityType
      }
    }

    setIsSubmitting(true);
    executeSaveDocument(parsedPayload, deleteDocument, saveDocument, deletePayload, dispatch)
      .then(onClose).finally(() => { setIsSubmitting(false) });
  }

  const handleMakeValueSelect = value => {
    setSelectedMakeValue(value);
    if (value.length === 0) {
      setSelectedModelValue([]);
      setSelectedYearValue([]);
      setSelectedSourceValue([]);
    }
  };

  const handleModelValueSelect = value => {
    setSelectedModelValue(value);
    if (value.length === 0) {
      setSelectedYearValue([]);
      setSelectedSourceValue([]);
    }
  };

  const handleYearValueSelect = value => {
    setSelectedYearValue(value);
    if (value.length === 0) {
      setSelectedSourceValue([]);
    }
  };

  const handleSourceValueSelect = value => {
    setSelectedSourceValue(value);
  };

  const addVehicleModel = (add) => {
    const combinedArray = selectedMakeValue.map((make) => {
      return selectedModelValue.map((model) => {
        return selectedYearValue.map((year) => {
          return selectedSourceValue.map((source) => {
            return { make, model, year, source };
          });
        });
      });
    }).flat().flat().flat();

    let matchedVehicleModelIds = [];
    combinedArray.flat().forEach(data => {
      const matchedVehicleModel = vehicleModels.filter(i => i.make === data.make &&
        i.model === data.model && i.yearOfManufacture === data.year &&
        (i.source === data.source || i.displaySource === data.source));

      if (matchedVehicleModel.length !== 0) {
        matchedVehicleModelIds = [...matchedVehicleModelIds, ...matchedVehicleModel.map(i => i.id)];
      }
    })

    const matchedVehicleModels = vehicleModels.filter(i => matchedVehicleModelIds.includes(i.id));

    let clonedVehicleModels = JSON.parse(JSON.stringify(matchedVehicleModels));
    clonedVehicleModels.map(data => {
      data.entityType = selectedEntityType;
      add(data.id);
    });

    setSelectedVehicleModels([...selectedVehicleModels, ...clonedVehicleModels]);
    setSelectedMakeValue([]);
    setSelectedModelValue([]);
    setSelectedYearValue([]);
    setSelectedSourceValue([]);

    form.setFieldValue('make', []);
    form.setFieldValue('model', []);
    form.setFieldValue('year', []);
    form.setFieldValue('source', []);
  }

  return (
    <ResponsiveModal
      onClose={onClose}
      className={style.modalPanel}
      isModalOpen={isModalOpen}
      title={t("Document.DocumentSetupModal.LoadSupportDocument")}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={() => setIsDirty(true)}>
        <Row className={style.fullWidthRow}>
          <Col xs={{ span: 24 }}>

            <Form.Item style={{ marginBottom: 5, marginLeft: 5 }}>
              <Text>{t("Document.DocumentSetupModal.AssociateTo")}</Text>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item>
              <Segmented
                disabled={!!document}
                value={selectedEntityType}
                options={[{
                  label: t("Document.DocumentSetupModal.VehicleTab"),
                  value: ENTITY_TYPE_VEHICLE_MODEL,
                },
                {
                  label: t("Document.DocumentSetupModal.DeviceTab"),
                  value: ENTITY_TYPE_DEVICE_MODEL,
                }]}
                onChange={(value) => {
                  setSelectedEntityType(value);
                }}
              />
            </Form.Item>
            {selectedEntityType === ENTITY_TYPE_VEHICLE_MODEL ?
              renderVehicleModelPanel() :
              <Space direction={"vertical"} className={style.MMYPanel} size={10}>
                <Form.Item name="deviceModelId" style={{ margin: 0 }} rules={[{ required: true, message: t("ValidationErrors.DeviceModelRequired") }]}>
                  <Select
                    showSearch
                    allowClear
                    style={{ width: "100%" }}
                    loading={isFetchingDeviceModel}
                    placeholder={t("Document.DocumentSetupModal.DeviceModel")}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={(allDeviceModels || []).map(i => {
                      return { label: i.name, value: i.id };
                    })}
                  />
                </Form.Item>
              </Space>
            }
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form form={documentForm} layout="vertical" name="documentForm">
              <Space direction={"vertical"} className={style.fullWidthRow} size={10}>
                <Form.Item>
                  <Segmented
                    options={[{
                      label: t("Document.DocumentSetupModal.LoadFile"),
                      value: "LoadFile",
                    },
                    {
                      label: t("Document.DocumentSetupModal.LoadUrl"),
                      value: "LoadUrl",
                    }]}
                    onChange={(value) => {
                      setSelectedDocumentType(value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="documentName"
                  label={t("Document.DocumentName")}
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: t("ValidationErrors.DocumentNameRequired") }]}>
                  <Input />
                </Form.Item>
                
                {selectedDocumentType === "LoadUrl" && <Form.Item
                  name="documentUrl"
                  label={t("Document.DocumentLink")}
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: t("ValidationErrors.DocumentRequired") }]}>
                  <Input />
                </Form.Item>
                }
                {selectedDocumentType === "LoadFile" && <Form.Item
                  name="fileName"
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: t("ValidationErrors.DocumentRequired") }]}>

                  <Dragger
                    showRemoveIcon={true}
                    accept={".pdf,.zip"}
                    beforeUpload={(incomingFile) => {
                      documentForm.setFieldValue('fileName', incomingFile);
                      setSelectedFile(incomingFile);
                      setIsZipFile(incomingFile.name.endsWith(".zip"));
                      return false;
                    }}
                    height={'fit-content'}
                    showUploadList={false}
                  >
                    <p className="ant-upload-text">
                      <div className={style.centerPanel}>
                        <div style={{ flex: "1" }}>
                          {selectedFile ? selectedFile.name :
                            <>
                              {t('Document.DocumentSetupModal.UploadDocument')}&nbsp;
                              <UploadOutlined />
                            </>
                          }
                        </div>
                      </div>
                    </p>
                  </Dragger>
                </Form.Item>
                }
                <Row>
                  <Col xs={{ span: 24 }} style={{display:"flex", justifyContent:"end"}}>
                    <Tooltip title={(fileList || []).length >= 10 ? t("Document.DocumentSetupModal.MaximumFileUploadHint", {limit:10}) : undefined}>
                      <Button
                        disabled={(fileList || []).length >= 10}
                        onClick={() => {
                          documentForm
                            .validateFields()
                            .then((e) => {
                              let uploadedData = { name: e.documentName };
                              if (selectedDocumentType === "LoadFile") {
                                uploadedData.file = e.fileName.file;
                                uploadedData.mimeType = e.fileName.file.type;
                              }
                              else {
                                uploadedData.url = e.documentUrl;
                                uploadedData.mimeType = "text/*";
                              }

                              documentForm.setFieldsValue({
                                fileName: "",
                                documentUrl: "",
                                documentName: "",
                              });
                              setSelectedFile(undefined);

                              const finalList = document ? [uploadedData] : [...fileList, ...[uploadedData]];
                              setFileList(finalList);
                              setIsDirty(true);
                              form.setFieldValue("documents", finalList.length > 0 ? finalList.length : undefined);
                            })
                        }}
                        type="default"
                      >
                        {t(selectedDocumentType === "LoadFile" ? 'Document.DocumentSetupModal.LoadFile' : 'Document.DocumentSetupModal.LoadUrl')}
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
                <Row className={style.fullWidthRow}>
                  <Col xs={{ span: 24 }}>
                  <p className="ant-upload-text">
                    {fileList.length !== 0 && 
                      <div style={{maxHeight:"250px", overflow:"auto"}}>
                        {fileList.map((file, index)=>{
                          return (
                            <div className={style.documentListPanel}>
                              <Space style={{ flex: "1", justifyContent: "space-between", padding: "5px 10px", alignItems: "center" }}>
                                <div className={style.centerPanel} style={{ flex: "0.8" }}>
                                  {file.mimeType === "text/*" ? <LinkOutlined style={{ marginRight: 15, fontSize: 30 }} /> : <FilePdfOutlined style={{ marginRight: 15, fontSize: 30, color:"darkred" }} />}
                                  <span className={style.centerPanel} style={{ flexDirection: "column" }}>
                                    <Text ellipsis={{ rows: 1, expandable: false }} className={style.fullWidthRow} style={{ textAlign: "left" }}>{file.name}</Text>
																		<Text type="secondary" className={style.fullWidthRow} style={{ textAlign: "left" }}>{file.file?.size ? getFileSize(file.file) : "-"}</Text>
                                  </span>
                                </div>
                                <div className={style.centerPanel} style={{ flex: "0.2" }}>
                                  <Button type="text" size="small" icon={<CloseOutlined />} onClick={()=>{
                                    setFileList((file) => {
                                      let updatedList = file.filter((_, i) => i !== index);
                                      setIsDirty(true);
                                      form.setFieldValue("documents", updatedList.length > 0 ? updatedList.length: undefined);
                                      return updatedList;
                                    });
                                  }} />
                                </div>
                              </Space>
                            </div>
                          )
                        })}
                      </div>
                    }
                  </p>
                  </Col>
                </Row>
              </Space>
            </Form>
            
            <Form.Item
              name="documents"
              style={{ margin: 0 }}
              rules={[{ required: true, message: t("ValidationErrors.DocumentRequired") }]}>
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.uploadModalFooter}>
          <Col xs={{ span: 24 }}>
            <Space>
              <Button
                loading={isSubmitting}
                disabled={isSubmitting || !isDirty}
                htmlType="submit"
                type="primary"
              >
                {t('Common.SaveButton')}
              </Button>
              <Button onClick={onClose}>
                {t('Common.CancelButton')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </ResponsiveModal>
  )
}

export default DocumentUploadModal;