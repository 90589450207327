import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, LinkOutlined, UploadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Row, Col, Form, Space, Typography, Upload, Input, Segmented, Tooltip } from 'antd';
import { ResponsiveModal } from '../../features/responsive';
import { useDispatch } from 'react-redux';
import style from './VehicleCompatibility.module.scss';
import { getFileSize } from '../../utils/files.js';

const { Text } = Typography;

const VehicleModelDocumentUploadModal = ({ documentList, isModalOpen, onClose, onComplete }) => {
  const { Dragger } = Upload;
  const { i18n, t } = useTranslation();
  const [form] = Form.useForm();
  const [documentForm] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState();
  const [fileList, setFileList] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState("LoadFile");
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (!isModalOpen) {
      setFileList([]);
      setSelectedFile();
      form.resetFields();
      setIsDirty(false);
    }
    else if (documentList) {
      setFileList(documentList)
      form.setFieldValue("documents", (documentList || []).length);
      setIsDirty(false);
    }
  }, [isModalOpen, documentList]);

  const onFinish = (e) => {
    onComplete(fileList);
  }

  return (
    <ResponsiveModal
      onClose={onClose}
      className={style.modalPanel}
      isModalOpen={isModalOpen}
      title={t("Document.DocumentSetupModal.LoadSupportDocument")}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={() => setIsDirty(true)}>
        <Row className={style.fullWidthRow}>
          <Col xs={{ span: 24 }}>
            <Form form={documentForm} layout="vertical" name="documentForm">
              <Space direction={"vertical"} className={style.fullWidthRow} size={10}>
                <Form.Item>
                  <Segmented
                    options={[{
                      label: t("Document.DocumentSetupModal.LoadFile"),
                      value: "LoadFile",
                    },
                    {
                      label: t("Document.DocumentSetupModal.LoadUrl"),
                      value: "LoadUrl",
                    }]}
                    onChange={(value) => {
                      setSelectedDocumentType(value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="documentName"
                  label={t("Document.DocumentName")}
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: t("ValidationErrors.DocumentNameRequired") }]}>
                  <Input />
                </Form.Item>

                {selectedDocumentType === "LoadUrl" && <Form.Item
                  name="documentUrl"
                  label={t("Document.DocumentLink")}
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: t("ValidationErrors.DocumentRequired") }]}>
                  <Input />
                </Form.Item>
                }
                {selectedDocumentType === "LoadFile" && <Form.Item
                  name="fileName"
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: t("ValidationErrors.DocumentRequired") }]}>

                  <Dragger
                    showRemoveIcon={true}
                    accept={".pdf,.zip"}
                    beforeUpload={(incomingFile) => {
                      documentForm.setFieldValue('fileName', incomingFile);
                      setSelectedFile(incomingFile);
                      return false;
                    }}
                    height={'fit-content'}
                    showUploadList={false}
                  >
                    <p className="ant-upload-text">
                      <div className={style.centerPanel}>
                        <div style={{ flex: "1" }}>
                          {selectedFile ? selectedFile.name :
                            <>
                              {t('Document.DocumentSetupModal.UploadDocument')}&nbsp;
                              <UploadOutlined />
                            </>
                          }
                        </div>
                      </div>
                    </p>
                  </Dragger>
                </Form.Item>
                }
                <Row>
                  <Col xs={{ span: 24 }} style={{ display: "flex", justifyContent: "end" }}>
                    <Tooltip title={(fileList || []).length >= 10 ? t("Document.DocumentSetupModal.MaximumFileUploadHint", {limit:10}) : undefined}>
                      <Button
                        disabled={(fileList || []).length >= 10}
                        onClick={() => {
                          documentForm
                            .validateFields()
                            .then((e) => {
                              let uploadedData = {
                                name: e.documentName,
                                mimeType: selectedDocumentType === "LoadFile" ? e.fileName.file.type : "text/*",
                                url: e.documentUrl,
                                file: e.fileName?.file
                              };

                              documentForm.setFieldsValue({
                                fileName: "",
                                documentUrl: "",
                                documentName: "",
                              });
                              setSelectedFile(undefined);

                              const finalList = [...fileList, ...[uploadedData]];
                              setFileList(finalList);
                              setIsDirty(true);
                              form.setFieldValue("documents", finalList.length > 0 ? finalList.length : undefined);
                            })
                        }}
                        type="default"
                      >
                        {t(selectedDocumentType === "LoadFile" ? 'Document.DocumentSetupModal.LoadFile' : 'Document.DocumentSetupModal.LoadUrl')}
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
                <Row className={style.fullWidthRow}>
                  <Col xs={{ span: 24 }}>
                    <p className="ant-upload-text">
                      {fileList.length !== 0 &&
                        <div style={{ maxHeight: "250px", overflow: "auto" }}>
                          {fileList.map((file, index) => {
                            return (
                              <div className={style.documentListPanel}>
                                <Space style={{ flex: "1", justifyContent: "space-between", padding: "5px 10px", alignItems: "center" }}>
                                  <div className={style.centerPanel} style={{ flex: "0.8" }}>
                                    {file.mimeType === "text/*" ? <LinkOutlined style={{ marginRight: 15, fontSize: 30 }} /> : <FilePdfOutlined style={{ marginRight: 15, fontSize: 30, color:"darkred" }} />}
                                    <span className={style.centerPanel} style={{ flexDirection: "column" }}>
                                      <Text ellipsis={{ rows: 1, expandable: false }} className={style.fullWidthRow} style={{ textAlign: "left" }}>{file.name}</Text>
                                      <Text type="secondary" className={style.fullWidthRow} style={{ textAlign: "left" }}>{file.file?.size ? getFileSize(file.file) : "-"}</Text>
                                    </span>
                                  </div>
                                  <div className={style.centerPanel} style={{ flex: "0.2" }}>
                                    <Button type="text" size="small" icon={<CloseOutlined />} onClick={() => {
                                      setFileList((file) => {
                                        let updatedList = file.filter((_, i) => i !== index);
                                        setIsDirty(true);
                                        form.setFieldValue("documents", updatedList.length > 0 ? updatedList.length : undefined);
                                        return updatedList;
                                      });
                                    }} />
                                  </div>
                                </Space>
                              </div>
                            )
                          })}
                        </div>
                      }
                    </p>
                  </Col>
                </Row>
              </Space>
            </Form>

            <Form.Item
              name="documents"
              style={{ margin: 0 }}
              rules={[{ required: true, message: t("ValidationErrors.DocumentRequired") }]}>
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.uploadModalFooter}>
          <Col xs={{ span: 24 }}>
            <Space>
              <Button
                disabled={!isDirty}
                htmlType="submit"
                type="primary"
              >
                {t('Common.SaveButton')}
              </Button>
              <Button onClick={onClose}>
                {t('Common.CancelButton')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </ResponsiveModal>
  )
}

export default VehicleModelDocumentUploadModal;