import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Tabs } from 'antd';

import { PlusCircleOutlined,AuditOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../features/header/headerSlice';
import InstallationDocumentListing from './InstallationDocumentListing.js';
import HardwareDocumentListing from './HardwareDocumentListing.js';
import { Permission } from '../../features/user/Permission.js';
import { ENTITY_TYPE_DEVICE_MODEL, ENTITY_TYPE_VEHICLE_MODEL } from './constant.js';
import { ResponsiveButton } from '../../features/responsive/ResponsiveButton.js';
import { Can } from '../../features/user/Can.js';
import DocumentUploadModal from './DocumentUploadModal.js';
import { RouteKey } from '../../config/page-key.js';
import { useHistory } from 'react-router-dom';

const Documents = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const [isDocumentUploadModalVisible, setIsDocumentUploadModalVisible] = useState(false);
	const [selectedEntityType, setSelectedEntityType] = useState(ENTITY_TYPE_VEHICLE_MODEL);

	const items = [
		{
			key: ENTITY_TYPE_VEHICLE_MODEL,
			label: t("Document.VehicleInstallation"),
			children: <InstallationDocumentListing />,
		},
		{
			key: ENTITY_TYPE_DEVICE_MODEL,
			label: t("Document.HardwareInstallation"),
			children: <HardwareDocumentListing />,
		}
	];

	useEffect(() => {
		dispatch(setPageTitle(t("Document.Title")));

		return () => {
			dispatch(setPageTitle(""));
		}
	}, [t]);

	return (
		<div>
			<Tabs defaultActiveKey="1" onChange={setSelectedEntityType} tabBarExtraContent={{
				right: <Space>
					<Can requiredPermission={[Permission.COMMON_EDIT]}>
						<ResponsiveButton onClick={() => {
								setIsDocumentUploadModalVisible(true);
						}}
							title={t("Document.AddSupportDocument")}
							icon={<PlusCircleOutlined />} />
					</Can>
					<Can requiredPermission={[Permission.AUDIT]}>
						<ResponsiveButton onClick={() => {
							history.push(ENTITY_TYPE_DEVICE_MODEL === selectedEntityType ?
								RouteKey.AUDIT_HARDWARE_ATTACHMENT : RouteKey.AUDIT_ATTACHMENT)
						}}
							title={t("Audits.Audits")}
							icon={<AuditOutlined />} />
					</Can>
				</Space>,
			}} items={items} tabBarStyle={{ paddingLeft: '20px', paddingRight: "20px",marginBottom:0, backgroundColor:"white" }} />
			<DocumentUploadModal
				entityType={ENTITY_TYPE_VEHICLE_MODEL}
				isModalOpen={isDocumentUploadModalVisible}
				onClose={() => {
					setIsDocumentUploadModalVisible(false);
				}} />
		</div>
	)
}

export default Documents;